import React from 'react';
import SocialMedia from './components/SocialMedia';

function App() {
  return (
    <div className="App">
      <SocialMedia />
    </div>
  );
}

export default App;
