import React, { useEffect, useState } from 'react'
import axios from 'axios';
import styles from './SocialMedia.module.scss';
import { wordpresData } from './service/Soctial.types';
import numeral from 'numeral';
declare global {
    interface Window { apiDataForReact: any; }
}

const SocialMedia = () => {
    const domain = window.location.origin;
    let postID = '';
    if (window.apiDataForReact !== undefined) {
        postID = window.apiDataForReact.post_id;
    }
    const [socialMedia, setSocialMedia] = useState<wordpresData | undefined>(undefined);
    const [youtubeApidData, setyoutubeApidData] = useState<any>('')


    const getUserData = async function () {
        try {
            const response: wordpresData = await axios.get(`${domain}/wp-json/wp/v2/influencer/${postID}`);
            const youtubeLink = response.data.acf.youtube['youtube-acf']
            if (youtubeLink.includes('user')) {
                setyoutubeApidData(response.data.acf.youtube['ilosc-yt']);
            } else if (youtubeLink.includes('channel')) {
                let chanelYt = youtubeLink?.replace(
                    /https:\/\/www.youtube.com\/channel\//i,
                    ''
                );
                const responseYt: any = await axios.get(`https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${chanelYt}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
                if (responseYt.data.items[0]) {
                    let count = numeral(responseYt.data.items[0].statistics.subscriberCount).format(
                        '0,0'
                    );
                    setyoutubeApidData(count);
                }
            }

            setSocialMedia(response);

        } catch (error: undefined | any) {
            console.log('error', error.message)
        }
    };

    useEffect(() => {
        getUserData();

    }, [])

    let facebookdata = numeral(socialMedia?.data.acf.facebook['ilosc-fb']).format('0,0');
    let instagramdata = numeral(socialMedia?.data.acf.instagram['ilosc-in']).format('0,0');
    let twitterdata = numeral(socialMedia?.data.acf.twitter['ilosc-tw']).format('0,0');
    let linkedinData = numeral(socialMedia?.data.acf.linkedin['ilosc-li']).format('0,0');
    let snapchatData = numeral(socialMedia?.data.acf.snapchat['ilosc-sn']).format('0,0');
    let tikTokData = numeral(socialMedia?.data.acf.tik_tok['ilosc-tik_tok']).format('0,0');


    return (
        <section className={styles.socialStats}>
            <div className='container'>
                <div className='row'>
                    {facebookdata !== '0' ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3>Facebook polubienia</h3>
                            </div>
                            <span>{facebookdata}</span>
                        </div>
                    ) : null}

                    {youtubeApidData ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3>Subskrypcje Youtube</h3>
                            </div>
                            <span>{youtubeApidData}</span>
                        </div>
                    ) : null}
                    {instagramdata !== '0' ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3>Instagram Obserwujący</h3>
                            </div>
                            <span>{instagramdata}</span>
                        </div>
                    ) : null}
                    {twitterdata !== '0' ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3> Twiter Śledzący</h3>
                            </div>
                            <span>{twitterdata}</span>
                        </div>
                    ) : null}
                    {(linkedinData !== '0') ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3> Linkedin Śledzący</h3>
                            </div>
                            <span>{linkedinData}</span>
                        </div>
                    ) : null}
                    {snapchatData !== '0' ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3> Snapchat Śledzący</h3>
                            </div>
                            <span>{snapchatData}</span>
                        </div>
                    ) : null}
                    {tikTokData !== '0' ? (
                        <div className='col-sm-3'>
                            <div className={styles.socialTitle}>
                                <h3> TikTok Śledzący</h3>
                            </div>
                            <span>{tikTokData}</span>
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    )
}

export default SocialMedia;
